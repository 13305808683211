import {
    Component, OnInit, AfterViewChecked, ElementRef, OnDestroy, ViewEncapsulation, Input, NgModule,
    ViewChild, Output, EventEmitter
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import {
    DxLoadPanelModule, DxPivotGridModule, DxChartModule, DxTooltipModule, DxResponsiveBoxModule,
    DxCheckBoxModule, DxSelectBoxModule, DxButtonModule, DxPopupModule, DxScrollViewModule
} from 'devextreme-angular';
import { DxPivotGridComponent, DxChartComponent } from 'devextreme-angular';
import { alert, confirm } from 'devextreme/ui/dialog';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { AnalyticService } from '../../services/analytic.service';
import { SellerService } from '../../services/seller.service';
import { PeriodService } from '../../services/period.service';
import { AppElementsService } from '../../services/app-element.service';
import { AnalyticContext } from '../../models/contexts/analytic-context';
import { AuthService } from '../../services/auth.service';
import { Analytic, ChartOrientation } from '../../models/analytic';
import { IgCache } from '../../models/ig-cache';
import { EnumBucketType, dateUoms } from '../../constants/enums';
import { Seller } from '../../models/seller';
import { AnalyticField } from '../../models/analytic-field';
import { dataFieldChildNodes } from 'devexpress-dashboard/model/index.metadata';
import { CoreComponentModule } from '../core-component.module';
import { ProcessingService } from '../../services/processing.service';
import { IGCacheRefresh } from '../../models/ig-cache-refresh';
import { toastrConstants } from '../../constants/constants';
import { HelperService } from '../../services/helper.service';
import { Period } from '../../models/period';
import { RecurrenceService } from '../../services/recurrence.service';
import { UpdateAnalyticRequest } from '../../models/update-analytic-request';
import { DistributionList } from '../../models/distribution-list';
import { DistributionListService } from '../../services/distribution-list.service';
import { ReportingParametersComponent } from '../reporting-parameters/reporting-parameters.component';

@Component({
    selector: 'app-analytic-viewer',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './analytic-viewer.component.html',
    styleUrls: ['./analytic-viewer.component.scss'],
    providers: [AnalyticService, SellerService, AuthService]
})
export class AnalyticViewerComponent implements OnInit, AfterViewChecked {
    @ViewChild(DxPivotGridComponent, { static: false }) pivotGrid: DxPivotGridComponent;
    @ViewChild(DxChartComponent, { static: false }) chart: DxChartComponent;

    // This determines if a loading panel should ever be shown, loadingVisible determines if the dashboard is currently loading.
    @Input() showLoadingPanel: boolean;
    @Input() sellerId: number;
    @Input() showChartControls: boolean;
    @Input() hasAdminView: boolean;

    @Output() analyticLoaded = new EventEmitter<Analytic>();
    @Output() analyticCreated = new EventEmitter();

    username: string;
    dataSource: PivotGridDataSource;
    dataSourceState: any;
    analytic: Analytic;
    analyticId: number;
    // keep local copy of what is saved to server, so that user can rename analytic without updating all fields
    unmodifiedAnalytic: Analytic;
    analyticFields: AnalyticField[] = [];
    igCacheRows: IgCache[];
    seller: Seller;
    legendVisible: boolean = true;
    loadingVisible = false;

    // 'Candlestick' and 'Stock' chart types are currently turned off - not available in CoreWin
    chartTypeOptions = ['Area', 'Bar', 'Bubble', 'FullStackedArea', 'FullStackedBar', 'FullStackedLine',
        'FullStackedSpline', 'FullStackedSplineArea', 'Line', 'RangeArea', 'RangeBar', 'Scatter', 'Spline', 'SplineArea',
        'StackedArea', 'StackedBar', 'StackedLine', 'StackedSpline', 'StackedSplineArea', 'StepArea', 'StepLine'];
    valueFormatOptions = ['Currency', 'Percent', 'FixedPoint'];
    valuePrecisionOptions = [0, 1, 2, 3, 4, 5];
    chartOrientationOptions: Array<any>;
    indicatorUrl: string = '../../../../assets/images/spinning-gears.gif';
    dateRangeLabelStr: string;
    isReportParamsVisible: boolean = false;
    valueAxisName: string;
    DEFAULTVALUEAXIS: string = 'defaultValueAxis';
    FULLSTACKEDVALUEAXIS: string = 'fullStackedValueAxis';
    normalizedPeriods: Period[];
    useMonthlyTreeView: boolean = true;
    initialCall: boolean = true;
    isOptionsPopupVisible: boolean = false;
    precision: number = 2;
    format: string = 'currency';
    showChartLoadingPanel: boolean = false;
    globalDistributionLists: DistributionList[];
    defaultUiViewId: number;

    constructor(private element: ElementRef,
        private http: HttpClient,
        private toast: ToastrService,
        private authService: AuthService,
        private analyticService: AnalyticService,
        private sellerService: SellerService,
        private periodService: PeriodService,
        private recurrenceService: RecurrenceService,
        private distributionListService: DistributionListService,
        private appElementsService: AppElementsService,
        private processingService: ProcessingService,
        private helperService: HelperService,
        private router: Router) {
            this.applySettings = this.applySettings.bind(this);
    }

    ngOnInit() {

        this.username = this.authService.getUserFromToken();
        this.chartOrientationOptions = [];
        for (const [key, value] of Object.entries(ChartOrientation)) {
            if (!Number.isNaN(Number(key))) {
                continue;
            }
            this.chartOrientationOptions.push({ id: value, name: key });
        }

        if (this.hasAdminView === undefined) {
            this.sellerService.getHasAdminView().subscribe(result => this.hasAdminView = result);
        }
        this.getDateRangeLabelStr();

        window.addEventListener('beforeunload', (event) => {
            localStorage.setItem('analyticRefreshInProgress', '[]');
        });

        this.analyticService.analyticResponse.subscribe(([analytic, analyticData, analyticFields]) => {
            this.analytic = analytic;
            this.setChartType(this.analytic.chartTypeName);
            this.analyticFields = analyticFields;
            const layout = JSON.parse(this.analytic.layoutString);
            this.setAnalyticFields(layout, false);
            this.analyticLoaded.emit(this.analytic);
            this.unmodifiedAnalytic = this.analytic;
            this.igCacheRows = this.prepAnalyticData(analyticData);
            this.dataSource?.reload();
            this.displayAnalytic(this.initialCall);
            this.loadingVisible = false;
            if (this.initialCall){
                this.initialCall = false;
            }
            this.precision = analytic.valuePrecision;
            this.format = analytic.valueFormat;
            this.defaultUiViewId = analytic.defaultUiViewId === undefined || analytic.defaultUiViewId === null ? 0 : analytic.defaultUiViewId;
        });
    }

    generateAnalytic(analyticId: number, beginDate?: string, endDate?: string, sellers?: string, initialCall: boolean = false) {
        this.loadingVisible = true;
        this.analyticId = analyticId;

        if (!this.username) {
            return;
        }

        const isApplyDefaults = localStorage.getItem(`${this.username}.applyDefaultLayouts`) !== false.toString();
        forkJoin([
            this.sellerService.getSellerWithSubordinateSetting(this.username),
            this.periodService.getPeriods(),
            this.recurrenceService.GetAllRecurrences(),
            this.distributionListService.getGlobalDistributionLists(),
            this.distributionListService.getDefaultDistributionList(isApplyDefaults, EnumBucketType.Analytic, this.analyticId),
        ]).subscribe(([seller, periods, recurrences, globalDistributionLists, defaultDistributionList]) => {
            this.seller = seller;

            this.globalDistributionLists = globalDistributionLists;
            this.globalDistributionLists.unshift(new DistributionList(0, '<None>'));

            this.normalizedPeriods = periods.map(p => {
                p.beginDate = this.helperService.getUTCMidnight(new Date(p.beginDate));
                p.endDate = this.helperService.getUTCEndOfDay(new Date(p.endDate));
                return p;
            });

            this.periodService.setStorageDates(seller.id, seller?.subordinates?.map(x => x?.id), this.username, this.hasAdminView).then(() => {
                const context: AnalyticContext = {
                    id: this.analyticId,
                    beginDate: beginDate ? new Date(beginDate) : new Date(localStorage.getItem('beginDate')),
                    endDate: endDate ? new Date(endDate) : new Date(localStorage.getItem('endDate')),
                    selectedSellers: sellers ? sellers : localStorage.getItem(`${this.username}.selectedSellers`),
                    seriesIdList: sessionStorage.getItem('selectedSeries'),
                    layoutString: null
                };
                if (isApplyDefaults && defaultDistributionList) {
                    const layout = JSON.parse(defaultDistributionList.layoutString);
                    context.selectedSellers = this.helperService.createBracketedIdString(layout.selectedRowKeys);
                    this.appElementsService.provideDistributionListChanged(defaultDistributionList.id);
                }
                const monthlyRecurrences = recurrences.filter(r => r.dateUomId === dateUoms.Month).map(r => r.id);
                this.getDateRangeLabelStr(context.beginDate, context.endDate);
                if (!initialCall){
                    this.promptCacheRefreshIfDirty(context);
                }
                const normalizedBeginDate = this.helperService.getUTCMidnight(context.beginDate);
                const normalizedEndDate = this.helperService.getUTCEndOfDay(new Date(this.helperService.dateToUTCISOString(context.endDate)));
                const beginDateRecurrences = periods.filter(p => p.beginDate.valueOf() === normalizedBeginDate.valueOf()).map(p => p.recurrenceId);
                const endDateRecurrences = periods.filter(p => p.endDate.valueOf() === normalizedEndDate.valueOf()).map(p => p.recurrenceId);
                this.useMonthlyTreeView = beginDateRecurrences.some(r => monthlyRecurrences.includes(r)) && endDateRecurrences.some(r => monthlyRecurrences.includes(r));

                if (!this.analyticId) {
                    this.loadingVisible = false;
                    return;
                }

                this.analyticService.analyticRequest.next(context);
            });
        });
    }

    promptCacheRefreshIfDirty(context: AnalyticContext): void {
        const normalizedDateRangeContext = {
            beginDate: this.helperService.getUTCMidnight(context.beginDate),
            endDate: this.helperService.getUTCEndOfDay(context.endDate)
        } as AnalyticContext;

        this.analyticService.getDirtyPeriodIds(normalizedDateRangeContext).subscribe(dirtyPeriodIds => {
            if (dirtyPeriodIds.length) {
                if (this.analyticService.isRefreshInProgress(dirtyPeriodIds)) {
                    alert('Refresh still in progress. Please wait to view analytics.', 'Refresh in Progress...');
                } else {
                    const msg = 'Not all periods in the selected range are up-to-date. Refresh cache now?';
                    confirm(msg, 'Refresh').then((dialogResult) => {
                        if (dialogResult) {
                            this.processingService.getProcessingInProgress().subscribe(inProgress => {
                                if (!inProgress) {
                                    this.loadingVisible = true;
                                    this.processingService.updateIgCache(new IGCacheRefresh(dirtyPeriodIds)).subscribe(result =>{
                                        this.loadingVisible = false;
                                        if (result === true){
                                            this.analyticService.setRefreshCompletedIds(dirtyPeriodIds);
                                            this.reloadAnalyticData(this.analytic.layoutString);
                                            this.toast.success('Analytics cache refresh completed');
                                        }
                                        else {
                                            this.analyticService.setRefreshCompletedIds(dirtyPeriodIds);
                                            this.toast.error('Analytics cache refresh incomplete. See logs for details.');
                                        }
                                    });
                                    this.analyticService.setRefreshInProgress(dirtyPeriodIds);
                                    this.toast.success('Analytics cache refresh in progress...');
                                } else {
                                    this.toast.error(toastrConstants.processingInProgress);
                                }
                            });
                        }
                    });
                }
            } else {
                this.analyticService.setRefreshCompletedPeriod(normalizedDateRangeContext, this.normalizedPeriods);
            }
        });
    }

    reloadAnalyticData(analyticLayoutString: string = null): void {
        this.periodService.setStorageDates(this.sellerId, this.seller?.subordinates?.map(x => x?.id), this.username, this.hasAdminView).then(() => {
            const context: AnalyticContext = {
                id: this.analyticId,
                beginDate: new Date(localStorage.getItem('beginDate')),
                endDate: new Date(localStorage.getItem('endDate')),
                selectedSellers: localStorage.getItem(`${this.username}.selectedSellers`),
                seriesIdList: sessionStorage.getItem('selectedSeries'),
                layoutString: analyticLayoutString
            };

            this.analyticService.getAnalyticData(context).subscribe(analyticData => {
                this.igCacheRows = this.prepAnalyticData(analyticData);
                this.dataSource?.reload();
                this.displayAnalytic();
                this.loadingVisible = false;
            });
        });
    }

    setDataSource(state: any): void {
        if (!this.dataSource) {
            this.dataSource = new PivotGridDataSource({
                retrieveFields: false,
                store: new CustomStore({
                    load: (params: any) => this.igCacheRows
                })
            });
            setTimeout(() => {
                this.dataSource.fields(state.fields);
            });
        } else {
            this.dataSource.fields(state.fields);
        }

        this.dataSourceState = state;
        this.dataSource.state(state);
        setTimeout(() => {
            this.showChartLoadingPanel = false;
        });
    }

    createNewAnalytic(name: string): void {
        this.loadingVisible = true;

        const newAnalytic: Analytic = {
            id: 0,
            name,
            layoutString: this.analytic.layoutString,
            sellerIdString: '[' + this.sellerId + ']',
            attributes: this.analytic.attributes,
            isDefault: false,
            showPointLabels: this.analytic.showPointLabels,
            showChart: this.analytic.showChart,
            chartTypeName: this.analytic.chartTypeName,
            chartOrientation: this.analytic.chartOrientation,
            valueFormat: this.analytic.valueFormat,
            valuePrecision: this.analytic.valuePrecision,
            showColumnTotals: this.analytic.showColumnTotals,
            showColumnGrandTotals: this.analytic.showColumnGrandTotals,
            showRowTotals: this.analytic.showRowTotals,
            showRowGrandTotals: this.analytic.showRowGrandTotals,
            defaultUiViewId: this.defaultUiViewId
        };

        this.analyticService.insertAnalytic(newAnalytic).subscribe(analytic => {
            if (!this.hasAdminView) {
                this.appElementsService.sideNavOuterToolbar.insertNavigationItem(EnumBucketType.Analytic, analytic.id, analytic.name, true);
            }
            this.analyticCreated.emit();
            this.toast.success(`Analytic '${analytic.name}' has been successfully created`);
            this.router.navigate([`/pages/analytic/${analytic.id}`]);
        }, error => {
            this.toast.error(error.error);
            this.loadingVisible = false;
        });
    }

    renameAnalytic(newName: string): void {
        this.loadingVisible = true;

        const renamedAnalytic: Analytic = {
            id: this.unmodifiedAnalytic.id,
            name: newName,
            layoutString: this.unmodifiedAnalytic.layoutString,
            sellerIdString: this.unmodifiedAnalytic.sellerIdString,
            attributes: this.unmodifiedAnalytic.attributes,
            isDefault: this.unmodifiedAnalytic.isDefault,
            showPointLabels: this.unmodifiedAnalytic.showPointLabels,
            showChart: this.unmodifiedAnalytic.showChart,
            chartTypeName: this.unmodifiedAnalytic.chartTypeName,
            chartOrientation: this.unmodifiedAnalytic.chartOrientation,
            valueFormat: this.unmodifiedAnalytic.valueFormat,
            valuePrecision: this.unmodifiedAnalytic.valuePrecision,
            showColumnTotals: this.unmodifiedAnalytic.showColumnTotals,
            showColumnGrandTotals: this.unmodifiedAnalytic.showColumnGrandTotals,
            showRowTotals: this.unmodifiedAnalytic.showRowTotals,
            showRowGrandTotals: this.unmodifiedAnalytic.showRowGrandTotals,
            defaultUiViewId: this.unmodifiedAnalytic.defaultUiViewId
        };
        const updateAnalyticRequest = new UpdateAnalyticRequest();
        updateAnalyticRequest.analytic = renamedAnalytic;
        updateAnalyticRequest.publishedSellerIds = null;

        this.analyticService.updateAnalytic(updateAnalyticRequest).subscribe(result => {
            if (result > 0) {
                this.appElementsService.sideNavOuterToolbar.renameNavigationItem(EnumBucketType.Analytic, this.analytic.id, newName);
                this.toast.success(`Analytic has been renamed to '${newName}' successfully`);
                this.analytic.name = newName;
                this.unmodifiedAnalytic.name = newName;

                const renameEvent: CustomEvent = new CustomEvent('rename-analytic', { detail: this.analytic });
                this.element.nativeElement.dispatchEvent(renameEvent);
            } else {
                this.toast.error('An error occurred while attempting to rename analytic');
            }
            this.loadingVisible = false;
        }, error => {
            this.toast.error(error.error);
            this.loadingVisible = false;
        });
    }

    saveAnalytic(analytic: Analytic, publishedSellerIds: number[], updatePermissionsOnly: boolean = false): void {
        const updateAnalyticRequest = new UpdateAnalyticRequest();
        updateAnalyticRequest.analytic = analytic;
        updateAnalyticRequest.publishedSellerIds = publishedSellerIds;
        updateAnalyticRequest.updatePermissionsOnly = updatePermissionsOnly;

        this.analyticService.updateAnalytic(updateAnalyticRequest).subscribe(result => {
            if (result > 0) {
                if (!updatePermissionsOnly) {
                    this.toast.success(`Analytic '${analytic.name}' has been saved successfully`);
                } else {
                    this.toast.success(`Analytic '${analytic.name}' permissions have been saved successfully`);
                }
            } else {
                this.toast.error('An error occurred while attempting to save analytic' + (updatePermissionsOnly ? ' permissions' : ''));
                this.loadingVisible = false;
            }
        }, error => {
            this.toast.error(error.error);
            this.loadingVisible = false;
        });
    }

    deleteAnalytic(analytic: Analytic): void {
        this.loadingVisible = true;

        this.analyticService.deleteAnalytic(analytic.id).subscribe(result => {
            if (result.result > 0) {
                this.appElementsService.sideNavOuterToolbar.removeNavigationItem(EnumBucketType.Analytic, analytic.id);
                this.toast.success(`Analytic '${analytic.name}' has been deleted successfully`);
                this.router.navigate([`/pages/analytic`]);
            } else {
                this.toast.error(`An error occurred while attempting to delete analytic '${analytic.name}'`);
                this.loadingVisible = false;
            }

        }, error => {
            this.toast.error(error.error);
            this.loadingVisible = false;
        });
    }

    displayAnalytic(initialCall: boolean = false, botAnalyticString: any = null): void {
        const state = botAnalyticString ? JSON.parse(botAnalyticString) : JSON.parse(this.analytic.layoutString);
        const period = state.fields.find(field => field.caption === 'Period');
        if (this.useMonthlyTreeView){
            period.dataType = 'date';
            period.groupInterval = 'year';
            period.area = 'column';
            state.fields.push({
                dataField: 'date',
                dataType: 'date',
                groupInterval: 'month',
                area: 'column'
            });
        } else {
            period.dataType = 'number';
            period.selector = this.dateValue.bind(this, period);
            period.sortBy = 'value';
            period.sortingMethod = this.dateSort;
        }
        this.setDataSource(state);

        if (this.igCacheRows.length === 0 && initialCall){
            this.toast.error('No data found for period. Please refresh the analytics cache and reload the page.');
        }

        const valueAxis = {
            name: this.valueAxisName,
            label: {format: this.analytic.valueFormat, precision: this.analytic.valuePrecision}
        };

        this.pivotGrid.instance.bindChart(this.chart.instance, {
            dataFieldsDisplayMode: 'splitPanes',
            alternateDataFields: true,
            customizeChart: (chartOptions: any) => {
                chartOptions.valueAxis[0] = valueAxis;
            },
            processCell: (cellData) => {
                cellData.chartDataItem.size = cellData.chartDataItem.val;
                cellData.chartDataItem.val1 = cellData.chartDataItem.val;
                cellData.chartDataItem.val2 = cellData.chartDataItem.val;
                return cellData;
            }
        });
    }

    loadState = () => {
        if (this.dataSource && this.dataSourceState) {
            return this.dataSourceState;
        }
    };

    dateValue = (f, value) => this.helperService.getDateString(value[f.dataField]);

    dateSort = (a, b) => {
        const dateA = new Date(a.value);
        const dateB = new Date(b.value);
        return dateA.getTime() - dateB.getTime();
    };

    saveState = (state) => {
        if (this.analytic !== undefined && this.analytic !== null) {
            this.setAnalyticFields(state, true);
        }
    };

    setAnalyticFields(layout: any, isUserChange: boolean): void {
        const fields = [];
        const existingLayout = isUserChange ? JSON.parse(this.analytic.layoutString) : null;
        let willReloadData: boolean = false;
        this.analyticFields.forEach(analyticField => {
            const modifiedField = layout.fields.find(x => x.dataField === analyticField.dataField);
            const existingField = isUserChange ? existingLayout?.fields?.find(x => x.dataField === analyticField.dataField) : null;
            if (modifiedField) {
                willReloadData = willReloadData || (isUserChange && (existingField.area === null || existingField.area === undefined || existingField.area.length === 0)
                  && (modifiedField.area !== null && modifiedField.area !== undefined && modifiedField.area.length > 0));
                modifiedField.caption = analyticField.caption;
                modifiedField.dataType = analyticField.dataType;
                modifiedField.summaryType = analyticField.summaryType;
                modifiedField.format = analyticField.format;

                fields.push(modifiedField);
            } else {
                fields.push(analyticField);
            }
        });
        layout.fields = fields;
        this.analytic.layoutString = JSON.stringify(layout);

        if (willReloadData) {
            this.loadingVisible = true;
            this.reloadAnalyticData(this.analytic.layoutString);
        }
    }

    prepAnalyticData(igCaches: IgCache[]): IgCache[] {
        const preppedData: IgCache[] = [];
        igCaches.forEach(igCache => {
            const newIgCache = igCache;

            igCache.dateFields.forEach(dateField => {
                newIgCache[dateField.key ? dateField.key : dateField.Key] = dateField.value ? dateField.value : null;
            });

            igCache.tagFields.forEach(tagField => {
                newIgCache[tagField.key ? tagField.key : tagField.Key] = tagField.value ? tagField.value : null;
            });

            igCache.textFields.forEach(textField => {
                newIgCache[textField.key ? textField.key : textField.Key] = textField.value ? textField.value : null;
            });

            // Fields that we don't want displaying in the column chooser
            delete newIgCache.dateFields;
            delete newIgCache.tagFields;
            delete newIgCache.textFields;
            delete newIgCache['id'];
            delete newIgCache['isIdentity'];
            delete newIgCache['isPublic'];

            preppedData.push(newIgCache);
        });
        return preppedData;
    }

    collapseAll(): void {
        const layout = JSON.parse(this.analytic.layoutString);
        layout.rowExpandedPaths = [];
        layout.columnExpandedPaths = [];

        this.analytic.layoutString = JSON.stringify(layout);
        this.displayAnalytic();
    }

    expandAll(): void {
        const layout = JSON.parse(this.analytic.layoutString);
        layout.rowExpandedPaths = [];
        layout.columnExpandedPaths = [];

        const newRowExpandedPaths = this.getRowExpandedPaths(layout, this.igCacheRows, 0, []);
        layout.rowExpandedPaths = newRowExpandedPaths;

        this.analytic.layoutString = JSON.stringify(layout);
        this.displayAnalytic();
    }

    getRowExpandedPaths(layout: any, filteredRows: IgCache[], rowIndex: number, path: any[]): any[][] {
        const rowExpandedPaths: any[][] = [];

        // Determine if rowIndex exists
        const indexField = layout.fields.find(x => x.area === 'row' && x.areaIndex === rowIndex);
        if (indexField) {
            const distinctValues = [];
            filteredRows.forEach(x => {
                if (!distinctValues.includes(x[indexField.dataField])
                    && (indexField.filterValues === undefined || indexField.filterValues.length === 0
                        || (indexField.filterType === undefined && indexField.filterValues.includes(x[indexField.dataField])
                            || indexField.filterType === 'exclude' && !indexField.filterValues.includes(x[indexField.dataField])))) {

                    distinctValues.push(x[indexField.dataField]);

                    // Construct new path
                    const newPath: any[] = [];
                    path.forEach(p => newPath.push(p));
                    newPath.push(x[indexField.dataField]);

                    rowExpandedPaths.push(newPath);

                    const newFilteredRows = filteredRows.filter(row => row[indexField.dataField] === x[indexField.dataField]);

                    const childPaths = this.getRowExpandedPaths(layout, newFilteredRows, (rowIndex + 1), newPath);
                    childPaths.forEach(p => rowExpandedPaths.push(p));
                }
            });
        }

        return rowExpandedPaths;
    }

    onRename(e): void {
        this.renameAnalytic(e);
    }

    onSave(publishedSellerIds: number[], updatePermissionsOnly: boolean): void {
        this.saveAnalytic(this.analytic, publishedSellerIds, updatePermissionsOnly);
    }

    onAdd(e): void {
        this.createNewAnalytic(e);
    }

    onDelete(): void {
        this.deleteAnalytic(this.analytic);
    }

    ngAfterViewChecked(): void {
        this.chart.instance.render();
    }

    showPicker(): void {
        this.isReportParamsVisible = true;
    }

    getDateRangeLabelStr(beginDate?: Date, endDate?: Date): void {
        this.dateRangeLabelStr = `${(beginDate ?? new Date(localStorage['beginDate'])).toDateString().substr(4)} to ${(endDate ?? new Date(localStorage['endDate'])).toDateString().substr(4)}`;
    }

    chartDrawn(e: any): void {
        if (this.chart?.instance?.getAllSeries()?.length >= 15) {
            this.legendVisible = false;
        } else {
            this.legendVisible = true;
        }
    }

    customizeTooltip = (e: any) => {
        if (e.valueText && e.seriesName) {
            return { text: `<span style="font-weight:bold;">${e.valueText}</span><br/><span style="font-style:italic;">${e.seriesName}</span>` };
        } else {
            return { text: e.valueText };
        }
    };

    getAxisValue(chartType: string): string {
        switch (chartType) {
            case 'FullStackedArea':
            case 'FullStackedBar':
            case 'FullStackedLine':
            case 'FullStackedSpline':
            case 'FullStackedSplineArea':
                return this.FULLSTACKEDVALUEAXIS;
            default:
                return this.DEFAULTVALUEAXIS;
        }
    }

    chartTypeChanged(e: any): void {
        this.setChartType(e.value);
    }

    setChartType(value: string): void {
        const newValueAxisName = this.getAxisValue(value);
        if (newValueAxisName !== this.valueAxisName) {
            this.valueAxisName = newValueAxisName;
        }
    }

    showOptionsPopup(e: any){
        this.isOptionsPopupVisible = true;
    }

    applySettings(e: any){
        const valueAxis = {
            name: this.valueAxisName,
            label: {format: this.analytic.valueFormat, precision: this.analytic.valuePrecision}
        };

        const state = JSON.parse(this.analytic.layoutString);
        const period = state.fields.find(field => field.caption === 'Period');
        if (this.useMonthlyTreeView) {
            period.dataType = 'date';
            period.groupInterval = 'year';
            period.area = 'column';
            state.fields.push({
                dataField: 'date',
                dataType: 'date',
                groupInterval: 'month',
                area: 'column'
            });
        } else {
            period.dataType = 'number';
            period.selector = this.dateValue.bind(this, period);
            period.sortBy = 'value';
            period.sortingMethod = this.dateSort;
        }
        this.setDataSource(state);

        const value = state.fields.find(field => field.caption === 'Value');
        value.format = {type: this.analytic.valueFormat, precision: this.analytic.valuePrecision};

        this.precision = this.analytic.valuePrecision;
        this.format = this.analytic.valueFormat;

        this.pivotGrid.instance.bindChart(this.chart.instance, {
            dataFieldsDisplayMode: 'splitPanes',
            alternateDataFields: true,
            customizeChart: (chartOptions: any) => {
                chartOptions.valueAxis[0] = valueAxis;
            },
            processCell: (cellData) => {
                cellData.chartDataItem.size = cellData.chartDataItem.val;
                cellData.chartDataItem.val1 = cellData.chartDataItem.val;
                cellData.chartDataItem.val2 = cellData.chartDataItem.val;
                return cellData;
            }
        });

        this.isOptionsPopupVisible = false;
    }

    valueFormatChanged(e: any){
        this.analytic.valueFormat = e.value;
    }

    valuePrecisionChanged(e: any){
        this.analytic.valuePrecision = e.value;
    }

    valueDefaultLayoutChanged(e: any) {
        this.analytic.defaultUiViewId = e.value;
    }

    pivotGridContentReady(e: any): void {
        // This allows the field chooser popup to be dragged & moved by user
        const fieldChooserPopup = e.component.getFieldChooserPopup();
        fieldChooserPopup.option('container', '.dx-viewport');
    }
}

@NgModule({
    imports: [
        CommonModule,
        DxLoadPanelModule,
        DxPivotGridModule,
        DxChartModule,
        DxTooltipModule,
        DxResponsiveBoxModule,
        DxCheckBoxModule,
        DxScrollViewModule,
        DxSelectBoxModule,
        DxPopupModule,
        DxButtonModule,
        CoreComponentModule
    ],
    declarations: [AnalyticViewerComponent],
    exports: [AnalyticViewerComponent]
})
export class AnalyticViewerModule { }
