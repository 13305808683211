import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { GearPropertyService } from 'src/app/pages/building-blocks/gear-property.service';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { SaveableBbProperty } from '../../models/saveable-bb-property';
import { HelperService } from '../../services/helper.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bb-assign-field-input',
  providers: [ {provide: SaveableBbProperty, useExisting: BbAssignFieldInputComponent }],
  templateUrl: './bb-assign-field-input.component.html',
  styleUrls: ['./bb-assign-field-input.component.scss']
})
export class BbAssignFieldInputComponent extends SaveableBbProperty implements OnInit  {
  @Input() datasource: any[];
  @Input() showAlias: boolean = true;
  @Output() datasourceChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  internalDatasource: any[];
  isValid: boolean = true;
  gearPropertyMappings: Record<string, any>;
  sourceFieldDropDownDatasource: { dataSource: any[], valueExpr: string, displayExpr: string };
  auxFieldDropDownDatasource: { dataSource: any[], valueExpr: string, displayExpr: string };
  removeAliasToastMessage: string = 'Alias name was removed because a source field was selected.';
  constructor(private gearPropertyService: GearPropertyService, private bbHelper: BuildingBlockHelperService, private helperService: HelperService, private toast: ToastrService) {
    super();
  }

  ngOnInit(): void {
    const idCols = this.bbHelper.getAllIdColumns().map(col => col.systemName);
    this.internalDatasource = this.datasource.map(col => {
      const colCopy = this.helperService.deepCopyTwoPointO(col);
      if(idCols.includes(colCopy['sourceName'])){
        colCopy['sourceName'] = this.bbHelper.convertIdToNameColumn(colCopy['sourceName']);
      }
      if(idCols.includes(colCopy['auxName'])){
        colCopy['auxName'] = this.bbHelper.convertIdToNameColumn(colCopy['auxName']);
      }
      return colCopy;
    });
    this.gearPropertyService.getMappings().subscribe(res => {
			this.gearPropertyMappings = res;
			this.sourceFieldDropDownDatasource = this.gearPropertyMappings['fields'];
      this.auxFieldDropDownDatasource = this.gearPropertyMappings['auxFields'];
    });
  }

  emitValue() {
    this.datasource = this.internalDatasource.map(entry => {
      const entryCopy = this.helperService.deepCopyTwoPointO(entry);
      if(entry['sourceName'] && entry['aliasName']){
        entryCopy['aliasName'] = null;
        if(!this.toast.findDuplicate(this.removeAliasToastMessage, true, false)){
          this.toast.info('Alias name was removed because a "Copy to" field was selected.');
        }
      }
      if(!entryCopy['sourceName']){
        if(entryCopy['auxName'].endsWith('_name')){
          entryCopy['auxName'] = this.bbHelper.convertNameToIdColumn(entryCopy['auxName']);
        }
        entryCopy['datatype'] = this.bbHelper.getDataColumnBySystemName(entryCopy['auxName'])?.type ?? 1;
      } else {
        const sourceType: string = this.bbHelper.getDataColumnBySystemName(entryCopy['sourceName']).datatype;
        const auxType: string = this.bbHelper.getDataColumnBySystemName(entryCopy['auxName']).datatype;

        const isSourceConvertable = entryCopy['sourceName']?.endsWith('_name');
        const isAuxConvertable = entryCopy['auxName'].endsWith('_name');
        if(isAuxConvertable && isSourceConvertable){
          if(sourceType === auxType){
            entryCopy['sourceName'] = this.bbHelper.convertNameToIdColumn(entryCopy['sourceName']);
            entryCopy['auxName'] = this.bbHelper.convertNameToIdColumn(entryCopy['auxName']);
          }
        } else if(isAuxConvertable && sourceType !== 'string'){
          entryCopy['auxName'] = this.bbHelper.convertNameToIdColumn(entryCopy['auxName']);
        } else if(isSourceConvertable && auxType !== 'string'){
          entryCopy['sourceName'] = this.bbHelper.convertNameToIdColumn(entryCopy['sourceName']);
        }
      }
      return entryCopy;
    });
    this.datasourceChange.emit(this.datasource.map(entry =>
      ({ sourceName: entry['sourceName'], auxName: entry['auxName'], aliasName: entry['aliasName'], datatype: entry['datatype'], outputId: entry['outputId'] })
    ));
  }

  checkForChanges(){
    if(this.dataGrid.instance.hasEditData()){
      this.bbHelper.setGridPropertyAsUnsaved('sourceAndAuxFields');
    } else {
      this.bbHelper.removeGridPropertyFromUnsaved('sourceAndAuxFields');
    }
  }

  saveInternalData(): Promise<void> {
    return this.bbHelper.saveWithUpdatedOutputId(this.internalDatasource, this.dataGrid);
  }

  // TODO: Change out this method for a simpler one when updating to DevEx 21.2 or higher
  filterToolbarItems(e){
    e.toolbarOptions.items = e.toolbarOptions.items.filter(item => ['addRowButton', 'revertButton'].includes(item.name) );
  }

  revertChanges(){
    this.isValid = true;
  }

  sortColumn(data) {
    const column = this as any;
    const value = column.calculateCellValue(data);
    return column.lookup.calculateCellValue(value);
  }

  aliasHasGearRuleNameCollision = (e: any) => !(e.value && this.bbHelper.hasGearRuleNameCollision(e.value));

  aliasHasIntraRuleGearNameCollision = (e: any) => !(e.value && this.bbHelper.hasIntraRuleGearNameCollision(e.value));

  aliasHasReservedNameCollision = (e: any) => !(e.value && this.bbHelper.hasReservedNameCollision(e.value));

  aliasHasFieldNameCollision = async (e: any) => !(e.value && (await this.bbHelper.hasFieldNameCollision(e.value)));

  validateAlias = (e: any) => {
    const doesOldNotMatchNew = e.newData['aliasName'] !== e.oldData['aliasName'];
    const doesNewAlreadyExist = this.internalDatasource.some(existingRow => existingRow['aliasName'] === e.newData['aliasName']);
    if(doesOldNotMatchNew && doesNewAlreadyExist){
      e.isValid = false;
      this.isValid = false;
    }
  };
}
