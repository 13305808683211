<div class="analytic-viewer">
    <div *ngIf="hasAdminView">
        <dx-button
            style="text-transform: unset !important"
            id="report-params-button"
            class="dx-button-default"
            icon="group"
            hint="Open Date/Account Selection"
            [text]="dateRangeLabelStr"
            (onClick)="showPicker()">
        </dx-button>
        <app-reporting-parameters [(isPopupVisible)]="isReportParamsVisible" (generate)="generateAnalytic(this.analyticId)"></app-reporting-parameters>
    </div>
    <div class="chart-container">
        <dx-chart
            id="chart"
            resolveLabelOverlapping="hide"
            [style.display]="analytic === undefined || analytic?.showChart ? 'block' : 'none'"
            [rotated]="analytic?.chartOrientation === 1"
            (onDrawn)="chartDrawn($event)">
            <dxo-common-series-settings [type]="analytic?.chartTypeName">
                <dxo-hover-style format="currency"></dxo-hover-style>
                <dxo-label [visible]="analytic?.showPointLabels">
                    <dxo-format [type]="format" [precision]="precision"></dxo-format>
                </dxo-label>
            </dxo-common-series-settings>
            <dxo-size [height]="320"></dxo-size>
            <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
            <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
                <dxo-format [type]="format" [precision]="precision"></dxo-format>
            </dxo-tooltip>
            <dxo-legend [visible]="legendVisible"></dxo-legend>
            <dxo-animation [enabled]="false"></dxo-animation>
        </dx-chart>
        <div *ngIf="showChartLoadingPanel" class="chart-loading-panel"></div>
    </div>
    
    <div class="options-button">
        <dx-button text="Analytic Settings" (onClick)="showOptionsPopup($event)">
        </dx-button>
    </div>

    <dx-pivot-grid
        id="pivotGrid"
        [dataSource]="dataSource"
        [allowSortingBySummary]="true"
        [allowFiltering]="true"
        [showColumnTotals]="analytic?.showColumnTotals"
        [showColumnGrandTotals]="analytic?.showColumnGrandTotals"
        [showRowTotals]="analytic?.showRowTotals"
        [showRowGrandTotals]="analytic?.showRowGrandTotals"
        [showBorders]="true"
        (onContentReady)="pivotGridContentReady($event)">
        <dxo-field-chooser [enabled]="true" [allowSearch]="true" applyChangesMode="instantly"></dxo-field-chooser>
        <dxo-field-panel
            [showRowFields]="true"
            [showColumnFields]="true"
            [showFilterFields]="true"
            [allowFieldDragging]="true"
            [visible]="true"
        > 
        </dxo-field-panel>
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-state-storing [enabled]="true" type="custom" [customLoad]="loadState" [customSave]="saveState"></dxo-state-storing>
        <dxo-export [enabled]="true" [fileName]="analytic?.name"></dxo-export>
    </dx-pivot-grid>
</div>

<dx-popup title="Analytic Options" 
    [width]="350" 
    [height]="450" 
    [(visible)]="isOptionsPopupVisible" 
    [showCloseButton]="true"
    [wrapperAttr]="{class:'settings-wrapper'}">
    <dx-scroll-view>
    <dxi-item>
        <div class="chart-options" *ngIf="analytic !== undefined && showChartControls">
            <div class="analytic-category">
                <div class="category-title">Chart Options:</div>
                <div class="chart-option">
                    <dx-check-box class="chart-option" [(value)]="analytic.showChart" text="Show Chart"></dx-check-box>
                </div>
                <div class="chart-option">
                    <dx-check-box class="chart-option" [(value)]="analytic.showPointLabels" text="Point Labels"></dx-check-box>
                </div>
                <div class="chart-option">
                    <span>Chart Type:</span>
                    <dx-select-box
                        class="chart-option"
                        [items]="chartTypeOptions"
                        [(value)]="analytic.chartTypeName"
                        stylingMode="underlined"
                        (onValueChanged)="chartTypeChanged($event)">
                    </dx-select-box>
                </div>
                <div class="chart-option">
                    <span>Orientation:</span>
                    <dx-select-box
                        class="chart-option"
                        [dataSource]="chartOrientationOptions"
                        valueExpr="id"
                        displayExpr="name"
                        stylingMode="underlined"
                        [(value)]="analytic.chartOrientation">
                    </dx-select-box>
                </div>
            </div>
            <div class="analytic-category">
                <div class="category-title">Pivot Grid Options:</div>
                <div class="chart-option">
                    <dx-check-box class="chart-option" [(value)]="analytic.showColumnTotals" text="Column Totals"></dx-check-box>
                </div>
                <div class="chart-option">
                    <dx-check-box class="chart-option" [(value)]="analytic.showColumnGrandTotals" text="Column Grand Totals"></dx-check-box>
                </div>
                <div class="chart-option">
                    <dx-check-box class="chart-option" [(value)]="analytic.showRowTotals" text="Row Totals"></dx-check-box>
                </div>
                <div class="chart-option">
                    <dx-check-box class="chart-option" [(value)]="analytic.showRowGrandTotals" text="Row Grand Totals"></dx-check-box>
                </div>
            </div>
            <div class="analytic-category">
                <div class="category-title">Formatting:</div>
                <div class="chart-option">
                    <span>Value Format:</span>
                    <dx-select-box
                        class="chart-option"
                        [items]="valueFormatOptions"
                        stylingMode="underlined"
                        [(value)]="analytic.valueFormat"
                        (onValueChanged)="valueFormatChanged($event)">
                    </dx-select-box>
                </div>
                <div class="chart-option">
                    <span>Precision:</span>
                    <dx-select-box
                        class="chart-option"
                        [items]="valuePrecisionOptions"
                        stylingMode="underlined"
                        [(value)]="analytic.valuePrecision"
                        (onValueChanged)="valuePrecisionChanged($event)">
                    </dx-select-box>
                </div>
            </div>
            <div class="analytic-category">
                <div class="category-title">Data Options:</div>
                <div class="chart-option">
                    <span>Default Layout:</span>
                    <dx-select-box
                        [(value)]="defaultUiViewId"
                        [dataSource]="globalDistributionLists"
                        valueExpr="id"
                        displayExpr="name"
                        class="chart-option"
                        stylingMode="underlined"
                        (onValueChanged)="valueDefaultLayoutChanged($event)">
                    </dx-select-box>
                </div>
            </div>
        </div>
    </dxi-item>
    </dx-scroll-view>
    <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    [options]="{
        text: 'Apply',
        stylingMode: 'contained',
        type: 'default',
        onClick: applySettings
    }">
    </dxi-toolbar-item>
</dx-popup>

<dx-load-panel
    #loadPanel
    [wrapperAttr]="{ class: 'spinning-gears-load-panel' }"
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '.analytic-viewer' }"
    [(visible)]="loadingVisible && showLoadingPanel"
    [indicatorSrc]="indicatorUrl"
    [showIndicator]="true"
    [showPane]="false"
    [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>
