<dx-popup [visible]="show" [closeOnOutsideClick]="false" title="Circular Dependency Detected In {{getLoopContainerTitle()}}">
    <dx-scroll-view>
        <div id="circular-dependency-container">
            A circular dependency has been detected between these gears.<br>
            Remove a source process from one of them to continue editing this rule.<br>
            This rule will also not process until the circular dependency has been removed.<br>
            <table width="100%">
                <tr>
                    <th>Gear Name</th>
                    <th>Gear Type</th>
                    <th>Gear Description</th>
                    <th></th>
                </tr>
                <tr *ngFor="let source of circularSources">
                    <td>{{source.name}}</td>
                    <td>{{getGearTypeStringFromGearTypeCode(source.gearTypeCode)}}</td>
                    <td>{{source.description}}</td>
                    <td>
                        <dx-button 
                            [disabled]="getGearTypeStringFromGearTypeCode(source.gearTypeCode) === 'Gearbox'"
                            (onClick)="clearSourceProcess(source.id)"
                            stylingMode="contained"
                            text="Clear Source Process"
                            type="default">
                        </dx-button>
                    </td>
                    <br>
                </tr>
            </table>

        </div>

    </dx-scroll-view>
</dx-popup>
